import './App.css';

function App()
{
    return (
        <div className="App">
            <header className="App-header">
                <h2><em>This site is currently under development for JP Development</em></h2>

                <h3 class="text-secondary">Please come back at a later date</h3>
            </header>
        </div>
    );
}

export default App;
